'use client'

import { Disclosure } from "@headlessui/react"
import { Bars3Icon, XMarkIcon } from "@heroicons/react/20/solid"
import { ReactNode } from "react"
import { useIFrameCompact } from "@/contexts/IFrameCompactProvider"

export const ResponsiveMenu = ({children, className}:{
  children: ReactNode
  className?: string
}) => {

  const { active } = useIFrameCompact()
  const key = active ? 'compact' : 'expand'

  return (
    <Disclosure as="nav" className={className} key={"disclosure-" + key} defaultOpen={!active}>
      {({open}) => (
        <>
          <Disclosure.Button 
          className="lg:hidden print:hidden relative inline-flex items-center justify-center rounded-md p-2 hover:bg-navy-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
          <span className="absolute -inset-0.5" />
          {open ? (
            <XMarkIcon className="block h-6 w-6" aria-hidden="true"  />
          ) : (
            <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
          )}
          <span className="pl-3 lg:sr-only">{open ? 'Close' : 'Open'} menu</span>
          </Disclosure.Button>
          <hr className="lg:hidden border-gray-600 mb-5 mt-2" />
          <Disclosure.Panel>
            {children}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}