'use client'

import { TocProvider, useToc } from "@/contexts/TocProvider";
import classNames from "classnames";
import { usePathname, useRouter } from "next/navigation"
import React, { ReactNode, useEffect, useId, useRef, useState } from "react"

interface heading {
    text: string
    id: string
    level: number
}

interface TocSidebarProps {
  children?: ReactNode
  main: ReactNode
  className?: string
  mainClassName?: string
}

export const TocSidebar = ({children, ...props}: TocSidebarProps) =>  {
  return <TocProvider>
    <TocSidebarInternal {...props}>{children}</TocSidebarInternal>
  </TocProvider>
}

const TocSidebarInternal = ({className, children, main, mainClassName}: TocSidebarProps) =>  {
    const [headings, setHeadings] = useState([] as heading[])
    const observer = useRef<IntersectionObserver>()
    const [activeId, setActiveId] = useState('')
    const [mutationCounter, setMutationCounter] = useState(0)
    const pathname = usePathname()
    const router = useRouter()
    const id = useId()
    const {enabled} = useToc()

    // Set the heading based on screen scroll position.
    useEffect(() => {
        const handleObsever = (entries:IntersectionObserverEntry[]) => {
            const actives = entries.filter((e) => e?.isIntersecting);

            if (actives.length) {
                setActiveId(actives[0].target.id)
            }
        }

        observer.current = new IntersectionObserver(handleObsever, {
            rootMargin: "0% 0% -95% 0%",
        })

        const elements = document.querySelectorAll(".ck h2[id], .ck h3[id]")
        elements.forEach((elem) => observer.current?.observe(elem))

        return () => observer.current?.disconnect()
    }, [pathname, mutationCounter])

    // Build the list of headings for the ToC.
    useEffect(() => {
        const elements = Array.from(document.querySelectorAll(".ck h2[id], .ck h3[id]"))
            .map((elem) => ({
                id: (elem as HTMLElement).id,
                text: (elem as HTMLElement).innerText.replace('¶', ''),
                level: Number(elem.nodeName.charAt(1))
            }))
        setHeadings(elements)

        return () => setHeadings([])
    }, [pathname, mutationCounter])

    // Watch for DOM changes are rebuild ToC when it occurs.
    useEffect(() => {
        const element = document.getElementById(id)
        
        if (element) {
            const observer = new MutationObserver(() => setMutationCounter(mutationCounter + 1))
            observer.observe(element, {childList: true, subtree: true})
            return () => observer.disconnect()
        }
    }, [id, mutationCounter])

    return (
        <>
          {(children !== undefined || (enabled && headings.length > 0)) && (
            <div className={classNames(className, "order-last col-span-1 sticky top-4 self-start hover:overscroll-y-contain overflow-y-auto lg:h-screen")}>
                { enabled && headings.length > 0 && (      
                  <nav className='toc mb-4'>
                      <h4 className="pl-2">On this page</h4>
                      <ul className="border-l border-navy-600 text-sm">
                          {headings.map(heading => (
                              <li key={heading.id} className={classNames(
                                  getClassName(heading.level),
                                  (activeId === heading.id) ? "border-navy-600" : "border-navy-600/0 hover:border-navy-400 hover:bg-gray-500", 
                                  "border-l-2 transition-colors duration-300"
                                  )}>
                                  <a 
                                  href={`#${heading.id}`}
                                  className={classNames((activeId === heading.id) ? "font-semibold" : "", "block py-1")}
                                  onClick={(e) => {
                                      e.preventDefault()
                                      const selector = `#${heading.id}`
                                      document.querySelector(selector)?.scrollIntoView({
                                          behavior: "smooth"
                                      })
                                      router.push(selector, {scroll: false})
                                  }}>{heading.text}</a>
                              </li>
                          ))}
                      </ul>
                  </nav>
                )}
                {children}
            </div>
          )}
          <div id={id} className={classNames(mainClassName, (enabled || children) ? 'col-span-2' : 'col-span-3')}>
              {main}
          </div>
        </>
    );
}

const getClassName = (level:number) => {
    switch (level) {
      case 2:
        return 'pl-2'
      case 3:
        return 'pl-6'
      case 4:
        return 'head4'
      default:
        return ''
    }
  }