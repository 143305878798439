'use client'
import { preferences, setVersionPreference } from "@/redux/features/versions";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { createContext, ReactNode, useContext, useState } from "react";

export type VersionContext = {
    name?: string
    current?: string
    preferred?: string
    versions?: string[]
}

const VersionContext = createContext<VersionContext>({})

export const useProductVersions = () => {
  const context = useContext(VersionContext)
  const versionPreferences = useAppSelector(preferences)
  const dispatch = useAppDispatch()

  if (context.name === undefined) {
    return {
      ...context,
      setPreferred: (version: string) => undefined
    }
  }
  context.preferred = versionPreferences[context.name]

  const setPreferred = (version: string) => {
    dispatch(setVersionPreference({product: context.name as string, version}))
  }

  return {
    ...context,
    setPreferred
  }
}

export const VersionsProvider = (props: VersionContext & {
  children: ReactNode
}) => {
  const versionPreferences = useAppSelector(preferences)
  props.preferred = props.name ? (versionPreferences[props.name] ?? undefined) : undefined
  return <VersionContext.Provider value={props}>
    {props.children}
  </VersionContext.Provider>
}